<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Acompanhamento de Comissões</titulo-pagina>
        </v-col>
        <v-col cols="12">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col class="d-block">
                  <div class="d-flex justify-space-between">
                    <div style="min-height: 36px" class="flex d-inline-flex align-center flex-wrap css-teste">
                      <v-btn color="white" class="mr-3 mt-1" small @click="obterComissoes">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                      <v-btn color="white" class="mr-3 mt-1" small @click="modalFiltro = true">
                        <v-icon>mdi-filter</v-icon>
                      </v-btn>
                      <v-chip
                          v-for="(value, key) in filtersChip"
                          :key="key"
                          class="mr-md-3 mt-1 d-flex justify-space-between"
                          :class="$vuetify.breakpoint.xsOnly ? 'v-chip-mobile' : ''"
                          close
                          color="orange"
                          label
                          outlined
                          @click:close="filtros[key] = null">
                        {{ key | primeiraLetraMaiuscula }}: {{ value | formataData }}
                      </v-chip>
                    </div>
                    <v-speed-dial
                        v-if="$vuetify.breakpoint.xsOnly && !!permissaoMaster"
                        style="position: fixed"
                        :top="false"
                        :bottom="true"
                        :right="true"
                        :left="false">
                      <template v-slot:activator>
                        <v-btn
                            :light="!baixaLiberada"
                            :dark="baixaLiberada"
                            color="orange darken-2"
                            small
                            :disabled="!baixaLiberada"
                            @click="baixaLiberada ? dialogRealizarPagamento.status = true : false">
                          <v-icon size="30px" class="mr-1">
                            mdi-cash-sync
                          </v-icon>
                          Baixar Comissões
                        </v-btn>
                      </template>
                    </v-speed-dial>
                    <v-btn
                        v-else-if="!!permissaoMaster"
                        :light="!baixaLiberada"
                        :disabled="!baixaLiberada"
                        :dark="baixaLiberada"
                        class="ml-3 mt-1"
                        color="orange"
                        @click="dialogRealizarPagamento.status = true"
                        small>
                      <v-icon class="mr-2">mdi-cash-sync</v-icon>
                      Baixar Comissões
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                      v-model="comissoesSelecionadas"
                      :headers="headersTabela"
                      :items="dadosTabela"
                      :disable-pagination="true"
                      :hide-default-footer="true"
                      item-class="rounded-lg"
                      :show-select="!!permissaoMaster"
                      @toggle-select-all="selecionarTodos"
                      locale="pt">
                    <template v-slot:header.data-table-select>
                      <v-simple-checkbox
                          v-show="comissoesPendentes.length > 0"
                          color="orange"
                          :indeterminate="selecaoIndeterminada"
                          :value="todosSelecionados"
                          @click="selecionarTodos"
                      />
                    </template>
                    <template v-slot:item.data-table-select="{select, isSelected, item}">
                      <v-simple-checkbox
                          color="orange"
                          v-if="comissoesPendentes.find(itm => itm.id === item.id)"
                          :value="isSelected"
                          @click="select(!isSelected)"
                      />
                    </template>
                    <template v-slot:item.valor="{ item }">
                      {{ item.valor | dinheiro }}
                    </template>
                    <template v-slot:item.criado_em="{ item }">
                      {{ item.criado_em | formataData }}
                    </template>
                    <template v-slot:item.tipo="{ item }">
                      {{ traduzirTipo(item.tipo) }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-btn text class="rounded-xl" readonly
                             :color="item.status === 1 ? 'orange darken-2':'green darken-1'">
                        {{ traduzirStatus(item.status) }}
                      </v-btn>
                    </template>
                    <template v-slot:item.detalhes="{ item }">
                      <v-icon color="blue"
                              @click="dialogDetalhes.inadimplencia_id = item.id; dialogDetalhes.status = true">
                        mdi-information-outline
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <dialog-filtro
          ref="DialogFiltro"
          :aberto="modalFiltro"
          :lista-status="listaStatus"
          :lista-tipos="listaTipos"
          v-model="filtros"
          @fechar="modalFiltro = false"
      />

      <dialog-realizar-baixa-comissoes
          ref="DialogDetalhes"
          :baixadas="comissoesBaixadas"
          :aberto="dialogRealizarPagamento.status"
          :comissoes="comissoesSelecionadas.length"
          @finalizar="obterComissoes"
          @realizar-baixa="realizarBaixaComissoes"
          @download-excel="obterComissoesExcel"
          @fechar="dialogRealizarPagamento.status = comissoesBaixadas = false"
      />

    </v-container>
  </menu-principal>
</template>

<script>
import mixinGeral from "@/mixin/geral";
import service from "@/services/comissao";
import TituloPagina from "@/components/TituloPagina";
import MenuPrincipal from "@/components/MenuPrincipal";
import DialogFiltro from "./dialogs/DialogFiltro";
import DialogRealizarBaixaComissoes from "./dialogs/DialogRealizarBaixaComissoes";
import moment from "moment";

export default {
  name: "ListagemComissao",
  mixins: [mixinGeral],
  components: {
    DialogRealizarBaixaComissoes,
    MenuPrincipal,
    TituloPagina,
    DialogFiltro,
  },
  data() {
    return {
      dialogDetalhes: {
        status: false,
        inadimplencia_id: null
      },
      comissoesBaixadas: false,
      dialogRealizarPagamento: {
        status: false,
      },
      modalFiltro: false,
      comissoesSelecionadas: [],
      listaStatus: [],
      listaTipos: [],
      filtros: {
        cliente_id: null,
        empresa_id: null,
        status_id: 1,
        criado_em: null,
        tipo_id: null,
      },
      dados: [],
    };
  },
  computed: {
    comissoesPendentes() {
      return this.dados.filter((comissao) => comissao.status === 1);
    },
    dadosTabela() {
      const dados = this.registrosFiltrados;
      if (dados.length === 0) {
        return []
      }
      return dados;
    },
    headersTabela() {
      return [
        {text: "Empresa", value: "empresa"},
        {text: "Cliente", value: "cliente"},
        {text: "Valor", value: "valor", align: "right"},
        {text: "Data", value: "criado_em", align: "left"},
        {text: "Tipo", value: "tipo", align: "center"},
        {text: "Status", value: "status", width: "0", align: "center"},
      ];
    },
    todosSelecionados() {
      return this.comissoesSelecionadas.length === this.comissoesPendentes.length;
    },
    selecaoIndeterminada() {
      return this.comissoesSelecionadas.length > 0 && !this.todosSelecionados;
    },
    filtersChip() {
      const retorno = {};
      if (this.filtros.cliente_id) retorno.cliente_id = `${this.traduzirCliente(this.filtros.cliente_id)}`;
      if (this.filtros.empresa_id) retorno.empresa_id = `${this.traduzirEmpresa(this.filtros.empresa_id)}`;
      if (this.filtros.tipo_id) retorno.tipo_id = `${this.traduzirTipo(this.filtros.tipo_id)}`;
      if (this.filtros.status_id) retorno.status_id = `${this.traduzirStatus(this.filtros.status_id)}`;
      if (this.filtros.criado_em) retorno.criado_em = this.filtros.criado_em;
      return retorno;
    },
    registrosFiltrados() {
      if (!this.dados) return [];
      let filtrados = [...this.dados];

      const filtrandoCliente = !!this.filtros.cliente_id;
      const filtrandoEmpresa = !!this.filtros.empresa_id;
      const filtrandoTipo = !!this.filtros.tipo_id;
      const filtrandoSatus = !!this.filtros.status_id;
      const filtrandoCriadoEm = !!this.filtros.criado_em;

      if (filtrandoCliente) filtrados = this.filtrarCliente(filtrados);
      if (filtrandoEmpresa) filtrados = this.filtrarEmpresa(filtrados);
      if (filtrandoTipo) filtrados = this.filtrarTipo(filtrados);
      if (filtrandoSatus) filtrados = this.filtrarStatus(filtrados);
      if (filtrandoCriadoEm) filtrados = this.filtrarDataCadastro(filtrados);

      return filtrados;
    },
    baixaLiberada() {
      if (!this.comissoesSelecionadas?.length) return false;
      return this.comissoesSelecionadas.some((f) => f.status === 1);
    },
  },
  methods: {
    selecionarTodos() {
      if (!this.todosSelecionados) {
        this.comissoesSelecionadas = this.comissoesPendentes;
      } else {
        this.comissoesSelecionadas = [];
      }
    },
    async obterComissoes() {
      await service.listar(this.form).then(({data}) => {
        this.dados = data.comissoes;
        this.listaTipos = data.lista_tipos;
        this.listaStatus = data.lista_status;
      });
    },
    traduzirCliente(cliente_id) {
      if (!cliente_id || !this.clientes.length) return "";
      return this.clientes.find((f) => f.id === cliente_id)?.nome ?? 'cliente não encontrado';
    },
    traduzirEmpresa(empresa_id) {
      if (!empresa_id || !this.empresas?.length) return "";
      return this.empresas.find((f) => f.id === empresa_id)?.razaoSocial ?? 'empresa não encontrada';
    },
    traduzirStatus(status) {
      if (!status || !this.listaStatus?.length) return "";
      return this.listaStatus.find((f) => f.id === status)?.nome ?? 'status não encontrado';
    },
    traduzirTipo(tipo) {
      if (!tipo || !this.listaTipos?.length) return "";
      return this.listaTipos.find((f) => f.id === tipo)?.nome ?? 'tipo não encontrado';
    },
    filtrarCliente(filtrados) {
      return filtrados.filter((f) => {
        return f.cliente_id === this.filtros.cliente_id;
      });
    },
    filtrarEmpresa(filtrados) {
      return filtrados.filter((f) => {
        return f.empresa_id === this.filtros.empresa_id;
      });
    },
    filtrarTipo(filtrados) {
      return filtrados.filter((f) => {
        return f.tipo === this.filtros.tipo_id;
      });
    },
    filtrarStatus(filtrados) {
      return filtrados.filter((f) => {
        return f.status === this.filtros.status_id;
      });
    },
    filtrarDataCadastro(filtrados) {
      return filtrados.filter((f) => {
        if (!this.filtros.criado_em) {
          return true;
        }
        let criado_em = [];
        if (!Array.isArray(this.filtros.criado_em)) {
          criado_em.push(this.filtros.criado_em);
        } else {
          criado_em = this.filtros.criado_em;
        }

        if (criado_em.length === 0) {
          return true;
        }

        if (criado_em.length === 1) {
          return moment(f.criado_em, 'YYYY-MM-DD')
              .isSameOrAfter(criado_em[0]);
        } else {
          if (moment(criado_em[0]).isAfter(criado_em[1])) {
            criado_em.reverse();
          }

          return moment(f.criado_em, 'YYYY-MM-DD')
              .isBetween(criado_em[0], criado_em[1], undefined, '[]');
        }
      });
    },
    async realizarBaixaComissoes() {
      this.ativarLoading();
      try {
        await service.baixarComissoes(this.comissoesSelecionadas);
        this.comissoesBaixadas = true;
      } catch (e) {
        this.comissoesBaixadas = false;
        this.interpretarErro(e);
      } finally {
        this.desativarLoading();
      }
    },
    async obterComissoesExcel() {
      this.ativarLoading();
      try {
        const { data } = await service.obterComissoesExcel(
            this.comissoesSelecionadas.map((f) => f.id)
        );
        const link = document.createElement('a');
        link.href = data.url;
        link.download="Comissões.xlsx";
        link.click();
        link.remove();
      } catch (e) {
        this.interpretarErro(e);
      } finally {
        this.desativarLoading();
      }
    },
  },
  async mounted() {
    await this.obterComissoes();
  },
}
</script>

<style scoped>
.css-teste::first-line {
  background-color: red;
}
</style>
