<template>
  <v-dialog
      :value="aberto"
      @input="$emit('input', $event)"
      width="700px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100 text-break">
          <div>Confirmar baixa de comissões</div>
          <v-btn icon dark @click="fechar()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text v-if="!baixadas">
        <v-row>
          <v-col cols="12" class="text-left" v-html="textoComissoes"/>
        </v-row>
      </v-card-text>
      <v-card-text class="mt-5" v-if="baixadas">
        <v-row>
          <v-col cols="12" md="6" class="text-center">
            <div class="botao-download d-inline-flex flex-column pa-5 text-center"
                 @click="downloadExcel">
              <v-icon class="mr-2 download-icon" size="90px">mdi-microsoft-excel</v-icon>
              Gerar Planilha
            </div>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <div class="botao-download d-inline-flex flex-column pa-5 text-center"
                 @click="downloadPDF">
              <v-icon class="mr-2 download-icon" size="90px">mdi-file-pdf-box</v-icon>
              Gerar PDF
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="mt-5" v-if="baixadas">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
                dark
                dense
                outlined
                color="orange darken-5"
                class="ma-3"
                @click="finalizar">
              Fechar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-5" v-else>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-around">
            <v-btn
                dark
                dense
                color="green darken-5"
                class="ma-3"
                @click="baixarComissoes">
              Confirmar
            </v-btn>
            <v-btn
                dark
                dense
                color="red darken-5"
                class="ma-3"
                @click="fechar">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import DialogShowArquivo from "@/components/DialogShowArquivo";
import MixinGeral from "@/mixin/geral";

export default {
  name: "DialogRealizarBaixaComissoes",
  mixins: [MixinGeral],
  components: {
    DialogShowArquivo
  },
  props: {
    aberto: Boolean,
    comissoes: Number,
    baixadas: Boolean
  },
  computed: {
    textoComissoes() {
      const inicio = `Ao clicar em confirmar,`;
      const concordancia = this.comissoes > 1
          ? ` as <strong>${this.comissoes} comissões</strong> selecionadas ficarão`
          : ' <strong>a comissão </strong> selecionada vai ficar';
      const fim = `com status de <strong class="green--text">"PAGO"</strong>.`;

      return `${inicio} ${concordancia} ${fim}`;
    }
  },
  methods: {
    finalizar(){
      this.$emit('finalizar');
      this.fechar();
    },
    fechar() {
      this.$emit('fechar', false);
    },
    async baixarComissoes() {
      this.$emit('realizar-baixa');
    },
    downloadExcel() {
      this.$emit('download-excel');
    },
    downloadPDF() {
      this.$emit('download-pdf');
    }
  }
};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}

.botao-download {
  border: 1px solid #DDDDDD;
  cursor: pointer;
  display: inline-flex;
  width: 150px;
  height: 150px;
}

.botao-download:hover {
  color: var(--cor-primaria-100) !important;
  border-color: currentColor;
}

.botao-download:hover > .download-icon {
  color: var(--cor-primaria-100) !important;
}
</style>
