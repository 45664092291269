<template>
  <v-autocomplete
      label="Cliente"
      placeholder="Selecione o cliente"
      :items="options_clientes"
      item-text="nome"
      item-value="id"
      dense
      outlined
      v-model="id_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-face-man"
      v-on:change="(event) => this.$emit('clienteSelecionado', id_selected)"
  />
</template>

<script>
import mixinGeral from "@/mixin/geral";

export default {
  name: "AutocompleteClientes",
  mixins: [mixinGeral],
  props: {
    id: Number,
  },
  data: () => ({
    options_clientes: [],
    id_selected: null,
  }),
  async created() {
    this.options_clientes = this.clientes;
    if (this.id) {
      this.id_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.id_selected = val;
    },
  },
};
</script>
