<template>
  <v-dialog
      :value="aberto"
      @input="$emit('input', $event)"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Filtrar Comissões</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row else>
          <v-col cols="12">
            <autocomplete-clientes
                :id="form.cliente_id"
                v-on:clienteSelecionado="clienteRetornado"/>
          </v-col>

          <v-col cols="12">
            <AutocompleteEmpresas
                :id="form.empresa_id"
                v-on:empresaSelecionada="empresaRetornada"/>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-select
                dense
                v-model="form.status_id"
                label="Status"
                :items="listaStatus"
                item-text="nome"
                item-value="id"
                clearable
                outlined
                hide-details
                @change="filtrar()"/>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-select
                dense
                v-model="form.tipo_id"
                label="Tipo"
                :items="listaTipos"
                item-text="nome"
                item-value="id"
                clearable
                outlined
                hide-details
                @change="filtrar()"/>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    label="Data do registro"
                    placeholder="Data do registro"
                    outlined
                    dense
                    hide-details
                    :value="form.criado_em | formataData"
                    readonly
                    clearable
                    v-bind="attrs"
                    @click:clear="form.criado_em = null; filtrar()"
                    v-on="on"
                    prepend-inner-icon="mdi-calendar"
                />
              </template>
              <v-date-picker
                  range
                  v-model="form.criado_em"
                  @input="filtrar()"
              />
            </v-menu>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
            color="orange darken-3"
            text
            @click="fechar">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "@/mixin/geral";
import AutocompleteEmpresas from "../autocomplete/Empresas.vue";
import AutocompleteClientes from "@/views/comissao/autocomplete/Clientes.vue";

export default {
  name: "DialogoFiltro",
  mixins: [mixinGeral],
  components: {
    AutocompleteClientes,
    AutocompleteEmpresas,
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    listaStatus: {
      type: Array,
      default: () => []
    },
    listaTipos: {
      type: Array,
      default: () => []
    },
    aberto: Boolean
  },
  data: () => ({
    form: {
      cliente_id: null,
      empresa_id: null,
      status_id: null,
      criado_em: null,
      tipo_id: null,
    }
  }),
  created() {
    Object.assign(this.form, this.value);
    this.filtrar();
  },
  watch: {
    value: {
      handler: function (value) {
        this.form = value;
      },
      deep: true
    }
  },
  methods: {
    filtrar() {
      this.$emit('input', this.form)
    },
    empresaRetornada(id) {
      this.form.empresa_id = id;
      this.filtrar();
    },
    clienteRetornado(id) {
      this.form.cliente_id = id;
      this.filtrar();
    },
    fechar() {
      this.$emit('fechar', false);
    }
  },
};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>
