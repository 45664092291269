import {backend, multipart} from "./backend";
import helpers from "../assets/js/helpers";

const listar = async () => {
    const uri = "api/comissao";
    return await backend.get(uri, helpers.montarHeaderToken());
};
const buscar = async (id) => {
    const uri = "api/comissao/" + id;
    return await backend.get(uri, helpers.montarHeaderToken());
};

const gravar = async (formulario) => {
    const uri = "api/comissao";

    const formData = new FormData();
    formData.append('venda_id', formulario.venda_id)
    formData.append('valor', formulario.valor)
    formData.append('itens', formulario.itens)
    formData.append('referencia', formulario.referencia)
    formData.append('observacao', formulario.observacao)
    formData.append('data_vencimento', formulario.data_vencimento)
    formData.append('fatura', formulario.fatura)

    return await multipart.post(uri, formData, helpers.montarHeaderToken());
};
const baixarComissoes = async (lista) => {
    const uri = "api/comissao/baixar";
    return await backend.post(uri, lista, helpers.montarHeaderToken());
}

const obterComissoesExcel = async (lista) => {
    const uri = "api/comissao/gerar-xlsx";
    return await backend.post(uri, lista, helpers.montarHeaderToken());
}

export default {
    listar,
    buscar,
    gravar,
    baixarComissoes,
    obterComissoesExcel,
};
