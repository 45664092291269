<template>
  <v-autocomplete
      label="Empresa"
      placeholder="Selecione a empresa"
      :items="options_empresas"
      item-text="razaoSocial"
      item-value="id"
      dense
      outlined
      v-model="id_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-domain"
      v-on:change="(event) => this.$emit('empresaSelecionada', id_selected)"
  />
</template>

<script>
import mixinGeral from "@/mixin/geral";

export default {
  name: "AutocompleteEmpresas",
  mixins: [mixinGeral],
  props: {
    id: Number,
  },
  data: () => ({
    options_empresas: [],
    id_selected: null,
  }),
  async created() {
    if (!this.permissaoMaster) return [];
    this.options_empresas = this.empresas;
    if (this.id) {
      this.id_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.id_selected = val;
    },
  },
};
</script>